declare global {
  interface SaveAssist {
  }

  interface Window {
    SaveAssistance: SaveAssist;
  }
}

export const isUndefined = (obj: any): obj is undefined =>
  typeof obj === "undefined";

export const isNull = (obj: any): obj is null => obj === null;

export const isNullOrUndefined = (obj: any): obj is null | undefined =>
  isNull(obj) || isUndefined(obj);

export const setWithDefault = (value: any, _default: any) =>
  isUndefined(value) || isNull(value) ? _default : value;

export const isJQueryPresent = (obj: JQuery): boolean =>
    obj.length > 0;

export const isJQueryAbsent = (obj: JQuery): boolean =>
    !isJQueryPresent(obj);

export const isPresent = (obj: any): boolean =>
  !isNullOrUndefined(obj) && obj !== '' && obj !== [] && obj !== {} && ((typeof obj === 'string' && !obj.match(/^\s*$/)) || true);

export const isBlank = (obj: any): boolean =>
  !isPresent(obj);
