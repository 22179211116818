import {isNullOrUndefined} from "utils/vardef";

declare global {
    interface SaveAssist {
        loadingModal: LoadingModal;
    }
}

function mustLoadModal(target: EventTarget | null, eventType: String) {
    let result: boolean = !isNullOrUndefined(target) && target instanceof Element &&
        target.classList.contains('js-with-loading-modal');

    switch (eventType) {
        case 'click':
            result &&= ['a', 'input', 'button'].includes(target.tagName.toLowerCase());
            break;
        case 'submit':
            result &&= ['form'].includes(target.tagName.toLowerCase());
            break;
        default:
            result = false;
    }

    return result;
}

export class LoadingModal {
    // Set up the event handlers to show the loading modal when submitting a js-with-loading-modal
    // form or clicking a js-with-loading-modal link/input/button and to hide the modal
    //
    // Returns this for chaining
    setUp() {
        const self = this;

        document.addEventListener('click', (e) => {
            const target = e.target;

            if (mustLoadModal(target, 'click')) {
                console.log(`Loading modal because of click on ${target.tagName}`, target);
                self.show();
            }
        }, {capture: true});

        document.addEventListener('submit', (e) => {
            const target = e.target;

            if (mustLoadModal(target, 'submit')) {
                console.log(`Loading modal because of submit on ${target.tagName}`, target);
                self.show();
            }
        }, {capture: true});

        return this;
    }

    // Show the modal
    //
    // Returns this for chaining
    show() {
        const modal = document.getElementById('js-loading-modal');
        console.log('Show now!');

        if (!isNullOrUndefined(modal)) {
            const viewportTopPosition = LoadingModal.getViewportTopPosition();

            // Show the modal
            modal.style.display = 'block';
            // Taking account of the scroll (put the top of the body to a negative position)
            // 70px is the size of the main-navigation
            modal.style.top = `${0 - viewportTopPosition + 70}px`;

            console.log('Shown!');
        }
        return this;
    }

    private static getViewportTopPosition(): number {
        const body = document.getElementsByTagName('body')[0];

        if (isNullOrUndefined(body)) {
            return 0;
        }

        const viewportOffset = body.getBoundingClientRect();
        // these are relative to the viewport, i.e. the window
        return viewportOffset.top;
    }

    // Hide the modal
    //
    // Returns this for chaining
    hide() {
        const modal = document.getElementById('js-loading-modal');
        console.log('Hide now!');

        if (!isNullOrUndefined(modal)) {
            modal.style.display = 'none';
            console.log('Hidden!');
        }
        return this;
    }
}

export const InitLoadingModal = () => {
    window.SaveAssistance.loadingModal = new LoadingModal();

    window.SaveAssistance.loadingModal.setUp();
};
