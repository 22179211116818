declare global {
  interface SaveAssist {
    makeFileDownloader: (downloadLink: HTMLAnchorElement, fileAsB64: string, filename: string, mimeType: string) => FileDownloader;
    mimeTypes: Object
  }
}

export class FileDownloader {
  downloadLink: HTMLAnchorElement;
  fileAsB64: string;
  filename: string;
  mimeType: string;

  constructor(downloadLink: HTMLAnchorElement, fileAsB64: string, filename: string, mimeType: string) {
    this.downloadLink = downloadLink;
    this.fileAsB64 = fileAsB64;
    this.filename = filename;
    this.mimeType = mimeType;
  }

  setUp() {
    const file = FileDownloader.b64toBlob(this.fileAsB64, this.mimeType);

    this.downloadLink.href = URL.createObjectURL(file);
    this.downloadLink.download = this.filename;
    this.downloadLink.click();

    return this;
  }

  static mimeTypes = {
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  };

  static b64toBlob(b64Data: string, contentType: string, sliceSize: number = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
  };
}

export const InitFileDownloader = () => {
  window.SaveAssistance.makeFileDownloader = (downloadLink: HTMLAnchorElement, fileAsB64: string, filename: string, mimeType: string) => new FileDownloader(downloadLink, fileAsB64, filename, mimeType);
  window.SaveAssistance.mimeTypes = FileDownloader.mimeTypes
};
