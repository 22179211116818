import {isNullOrUndefined} from "utils/vardef";

export const HorizontalScroller = () => {
  // duration of scroll animation
  const scrollDuration = 300;
  // paddles
  const leftPaddle = document.getElementsByClassName('hs-left');
  const rightPaddle = document.getElementsByClassName('hs-right');

  // get some relevant size for the paddle triggering point
  const paddleMargin = 40;

  // get wrapper width
  const getMenuWrapperSize = function () {
    const outerWidth = $('.hs-wrapper').outerWidth();

    return isNullOrUndefined(outerWidth) ? 0 : outerWidth;
  };
  let menuWrapperSize = getMenuWrapperSize();
  // the wrapper is responsive
  $(window).on('resize', function () {
    menuWrapperSize = getMenuWrapperSize();
  });
  // size of the visible part of the menu is equal as the wrapper size
  let menuVisibleSize = menuWrapperSize;

  // get total width of all menu items
  // get item dimensions
  const getItemSize = function ($item) {
    const outerWidth = $item.outerWidth(true);

    return isNullOrUndefined(outerWidth) ? 0 : outerWidth;
  };

  const getMenuSize = () => $('.hs-scrollable .hs-item').map((i, e) => getItemSize($(e)))
    .get().reduce((acc, value) => acc + value, 0);

  const menuSize = getMenuSize();
  // get how much of menu is invisible
  let menuInvisibleSize = menuSize - menuWrapperSize;

  // get how much have we scrolled to the left
  const getMenuPosition = function () {
    const scrollLeft = $('.hs-scrollable').scrollLeft();

    return isNullOrUndefined(scrollLeft) ? 0 : scrollLeft;
  };

  // finally, what happens when we are actually scrolling the menu
  const $scrollable = $('.hs-scrollable');

  $scrollable.on('scroll', function () {
    // get how much of menu is invisible
    menuInvisibleSize = menuSize - menuWrapperSize;
    // get how much have we scrolled so far
    const menuPosition = getMenuPosition();

    const menuEndOffset = menuInvisibleSize - paddleMargin;

    // show & hide the paddles
    // depending on scroll position
    if (menuEndOffset < 0) {
      // Special case where there is no need for scroll
      $(leftPaddle).fadeOut();
      $(rightPaddle).fadeOut();
    } else if (menuPosition <= paddleMargin) {
      $(leftPaddle).fadeOut();
      $(rightPaddle).fadeIn();
    } else if (menuPosition < menuEndOffset) {
      // show both paddles in the middle
      $(leftPaddle).fadeIn();
      $(rightPaddle).fadeIn();
    } else if (menuPosition >= menuEndOffset) {
      $(leftPaddle).fadeIn();
      $(rightPaddle).fadeOut();
    }

    // Debug important values
    console.log('menuWrapperSize:', menuWrapperSize);
    console.log('menuSize:', menuSize);
    console.log('menuInvisibleSize:', menuInvisibleSize);
    console.log('menuPosition:', menuPosition);
    console.log('menuEndOffset:', menuEndOffset);
  });

  // scroll to left
  $(rightPaddle).on('click', function () {
    $('.hs-scrollable').animate({scrollLeft: menuInvisibleSize}, scrollDuration);
  });

  // scroll to right
  $(leftPaddle).on('click', function () {
    $('.hs-scrollable').animate({scrollLeft: '0'}, scrollDuration);
  });

  $scrollable.trigger('scroll');
};
