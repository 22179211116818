import {isBlank} from "utils/vardef";

export const WordCounter = () => {
  $(document).on('input change', '[data-word-counter-limit][data-word-counter-text]', (e) => {
    const $content = $(e.currentTarget);
    const limit = parseInt($content.data('word-counter-limit') as string);
    const text = $content.data('word-counter-text');
    const $text = $(text);

    function getLength() {
      const text = isBlank($content.text()) ? $content.val() as string : $content.text();

      return text.length;
    }

    if (limit - getLength() >= 0) {
      $text.html(`<div class="form-text text-muted">${limit - getLength()} caractères restants.</div>`);
    } else {
      $text.html(`<div class="form-text text-danger">${Math.abs(limit - getLength())} caractères en trop.</div>`);
    }
  });
};
