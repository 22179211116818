import {isJQueryPresent, isNullOrUndefined} from "./vardef";

export const getNumberValue = (input: JQuery | string, defaultValue: number = 20): number => {
    let value: number = defaultValue;

    if (typeof input === 'string') {
        value = parseFloat(input);
    }
    else if (isJQueryPresent(input) && !isNullOrUndefined(input.val())) {
        const inputValue: any = input.val();

        value = parseFloat(inputValue);
    }

    if (isNaN(value)) {
        value = defaultValue;
    }

    return value;
};

export const roundToXDecimals = (value: number, x: number = 2) => {
    let str = `${value.toString()}e${x.toString()}`;
    return Number(`${Math.round(parseFloat(str)).toString()}e-${x.toString()}`);
};

export const getValue = (input: JQuery): string => {
    let value: string = '';

    if (isJQueryPresent(input) && !isNullOrUndefined(input.val())) {
        const inputValue: any = input.val();

        value = String(inputValue);
    }

    return value;
};

export const getCurrencyValue = (input: JQuery | string, defaultValue: number = 20) => {
    let value: string = '';

    if (typeof input === 'string') {
        value = input;
    }
    else if (isJQueryPresent(input)) {
        value = getValue(input)
    }

    return getNumberValue(value.replace(/[€ ]/g, ''), defaultValue);
};

export const getFile = (input: JQuery | HTMLInputElement): File | null => {
    let files: FileList | null = null;
    let file: File | null = null;

    if (jQuery.prototype.isPrototypeOf(input)) {
        files = (input[0] as HTMLInputElement).files;
    } else if (input instanceof HTMLInputElement) {
        files = input.files;
    }

    if (!isNullOrUndefined(files)) {
        file = files[0];
    }

    return file;
};

export const checkedAmount = (checkedRowsSelector: string, amountSelector: string, negativeAmountClass: string, getValueFn: (input: JQuery) => string = getValue): number =>
  roundToXDecimals(
    $(checkedRowsSelector)
      .closest('tr')
      .find(amountSelector)
      .map((_i, element): string => {
          const value = getValueFn.call(this, $(element)).replace(' ', '');

          if ($(element).hasClass(negativeAmountClass)) {
              return '-' + value;
          }

          return value;
      })
      .map((_i, element) => getNumberValue(element, 0))
      .get()
      .reduce((acc, val) => acc + val, 0)
  );
