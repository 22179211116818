// To auto resize on textareas with class .js-expandable-textarea
export const AutoExpandableTextaera = () => {
  
  // Must use because on ready load show page
  $(document).on('input', '.js-expandable-textarea', (e) => {
    console.log('Input on ', e.currentTarget);
    // Usefull line in order to resize if users delete his content
    e.currentTarget.style.height = 'auto';
    // Main line to allow auto expandable textarea
    e.currentTarget.style.height = (e.currentTarget.scrollHeight) + 'px';
  }).on('ready', () => $('.js-expandable-textarea:not(:empty):visible').trigger('input'));
};
