export const HideFollowUp = () => {
  $(document).on('click', '[data-hide-follow-up]', (e) => {
    const $target = $(e.currentTarget);
    const hide = $target.data('hide-follow-up');

    if (hide === 'true' || hide === true) {
      $('body').removeClass('show-sidebar');
    } else {
      $('body').addClass('show-sidebar');
    }
  });
};
