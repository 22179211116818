import {Sanitize} from "./sanitize";
import {isNullOrUndefined, isPresent} from "utils/vardef";

export const ContentEditable = () => {
  // Ensure the element won't spread on the horizontal axis
  $(document).on('input', '[contenteditable="true"]', (e) => {
    const $element = $(e.currentTarget);

    const maxWidth = $element.css('maxWidth');

    // Cannot use `one`, since the event would be triggered only for the first input of any matching
    // element of the page so we set the maxWidth only when it is undefined or 100%.
    if ((isPresent($element.text()) || isPresent($element.html())) && (isNullOrUndefined(maxWidth) || maxWidth === '100%')) {
      // @ts-ignore
      $element.css('maxWidth', $element.css('width'));
    }
  });

  $('[contenteditable="true"]').trigger('input');

  // @ts-ignore
  const sanitizer = new Sanitize({
    elements: ['div']
  });

  function createElementsFromHTML(htmlString: string): Element | Text | NodeList {
    const template: HTMLTemplateElement = document.createElement('template');
    template.innerHTML = htmlString.trim();

    const content: DocumentFragment = template.content;

    if (content.childNodes.length < 2) {
      const child = content.childNodes[0];

      if (isNullOrUndefined(child)) {
        return createElementFromHTML('');
      } else if (child instanceof Text) {
        return child;
      } else if (child instanceof Element) {
        return child;
      }

      return createElementFromHTML(isNullOrUndefined(child.textContent) ? '' : child.textContent);
    }

    return content.childNodes;
  }

  function createElementFromHTML(html: string|HTMLCollection): Element | Text {
    if (typeof(html) === 'string') {
      const template: HTMLTemplateElement = document.createElement('template');
      template.innerHTML = html.trim();
      const content: DocumentFragment = template.content;

      const firstElementChild: Element | null = content.firstElementChild;

      return isNullOrUndefined(firstElementChild) ? document.createTextNode(html) : firstElementChild;
    }

    const firstElementChild: Element | null = html.item(0);

    return isNullOrUndefined(firstElementChild) ? document.createTextNode('') : firstElementChild;
  }

  const nodeProcessor = (node: Element | Text | null): String[] => {
    if (isNullOrUndefined(node)) {
      return [''];
    } else if (node instanceof Text) {
      if (isNullOrUndefined(node.textContent)) {
        return [''];
      } else {
        return [node.textContent];
      }
    }

    const elements: Element | Text | NodeList = createElementsFromHTML(node.innerHTML);

    if (elements instanceof Text) {
      if (isNullOrUndefined(elements.textContent)) {
        return [''];
      } else {
        return [elements.textContent];
      }
    } else if (elements instanceof NodeList) {
      return [].map.call(elements, x => nodeProcessor(x));
    }

    return nodeProcessor(createElementFromHTML(elements.innerHTML));
  };

  function contentProcessor(node: Node): String[] {
    const childNodes: NodeList = sanitizer.clean_node(node).childNodes;

    return [].map.call(childNodes, x => nodeProcessor(x)).flat();
  }

  $(document).on('input', '[contenteditable="true"][data-content-for]', (e) => {
    const $contentEditable = $(e.currentTarget);
    const input = $contentEditable.data('content-for');
    const $input = $(input);
    const lines: String[] = contentProcessor($contentEditable[0]);
    const content = lines.join('<br/>').replace(/(<br.*?>)/g, '\n');

    $input.val(content);
    $input.trigger('change');
    console.log("[ContentEditable] Content:", content);
  });
};
