/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import AreYouSure from '../areYouSure';
import NoEnterSubmit from '../utils/noEnterSubmit';
import DataTypeCurrency from '../utils/formattedInputs';
import {InitLoadingModal} from "utils/loadingModal";
import {InitFileDownloader} from "utils/fileDownloader";
import {AutoSubmitter} from "utils/autoSubmitter";
import {ContentEditable} from "utils/contentEditable";
import {WordCounter} from "utils/wordCounter";
import '../stylesheets/application.scss';
import {AutoExpandableTextaera} from "utils/autoExpandableTextarea";
import {HorizontalScroller} from "utils/horizontalScroller";
import {HideFollowUp} from "utils/hideFollowUp";

require('jquery-editable-select');

$(() => {
  $('.select2').select2({
    theme: 'bootstrap4',
    language: 'fr'
  });
  $('.editable-select').editableSelect();
  AreYouSure();
  NoEnterSubmit();
  DataTypeCurrency();
  InitLoadingModal();
  InitFileDownloader();
  AutoSubmitter();
  ContentEditable();
  WordCounter();
  AutoExpandableTextaera();
  HorizontalScroller();
  HideFollowUp();
});
