const NoEnterSubmit = (cssClass: string = '.js-NoEnterSubmit') => {
    $(document).on('keypress keydown keyup', cssClass, (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            return false;
        }
        return true;
    });
};

export default NoEnterSubmit;
