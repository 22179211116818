import {isNullOrUndefined} from '../utils/vardef';

const AreYouSure = (cssClass: string = 'js-areYouSure') => {
  const modifiedInputs = new Set();
  const defaultValue = "defaultValue";
  const forms = $(`form.${cssClass}`);
  const elements = forms.find('input, select, textarea');

  // store default values
  elements.bind('beforeinput', evt => {
    const target:any = evt.target;
    if (!(defaultValue in target || defaultValue in target.dataset)) {
      target.dataset[defaultValue] = (
        "" + (target.value || target.textContent)
      ).trim();
    }
  });


  // detect input modifications
  elements.bind('input', evt => {
    const target:any = evt.target;
    let original:String;
    if (defaultValue in target) {
      original = target[defaultValue];
    } else {
      original = target.dataset[defaultValue];
    }

    if (original !== ("" + (target.value || target.textContent)).trim()) {
      if (!modifiedInputs.has(target)) {
        modifiedInputs.add(target);
      }
    } else if (modifiedInputs.has(target)) {
      modifiedInputs.delete(target);
    }
  });

  addEventListener('beforeunload', evt => {
    if (modifiedInputs.size) {
      const unsavedChangesWarning = "Vous avez modifié des champs sans sauvegarder.";
      evt.returnValue = unsavedChangesWarning;
      return unsavedChangesWarning;
    }
  });
};


export default AreYouSure;
