// Jquery Dependency

import {getValue} from "./inputUtils";
import {isJQueryAbsent, isNull} from "./vardef";

const DataTypeCurrency = (input: JQuery | null = null) => {
  if (isNull(input) || isJQueryAbsent(input)) {
    $("input[data-type='currency']")
      .each((_, element) => formatCurrency($(element)))
      .on({
        keyup: (e) => formatCurrency($(e.currentTarget)),
        blur: (e) => formatCurrency($(e.currentTarget), "blur")
      });
  } else {
    formatCurrency(input, 'blur');
  }
};

export default DataTypeCurrency;

// format number 1000000 to 1,234,567
const formatNumber = (n: string): string => n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");

// Validates decimal side and puts cursor back in right position.
const formatCurrency = (input: JQuery, blur: string | null = null) => {
  let inputVal: string = getValue(input);

  // don't validate empty input
  if (inputVal === '') {
    return;
  }

  // original length
  const originalLength = inputVal.length;

  // initial caret position
  let caretPosition = input.prop("selectionStart");

  // check for decimal
  if (inputVal.indexOf(".") >= 0) {

    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    const decimalPosition = inputVal.indexOf(".");

    // split number by decimal point
    let integralPart = inputVal.substring(0, decimalPosition);
    let decimalPart = inputVal.substring(decimalPosition);

    // add commas to left side of number
    integralPart = formatNumber(integralPart);

    // validate right side
    decimalPart = formatNumber(decimalPart);

    // On blur make sure 2 numbers after decimal
    if (blur === "blur") {
      decimalPart += "00";
    }

    // Limit decimal to only 2 digits
    decimalPart = decimalPart.substring(0, 2);

    // join number by .
    inputVal = `${integralPart}.${decimalPart}`;

  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    inputVal = formatNumber(inputVal);
    inputVal = `${inputVal}`;

    // final formatting
    if (blur === "blur") {
      inputVal += ".00";
    }
  }

  // send updated string to input
  input.val(inputVal);

  // put caret back in the right position
  const updatedLength = inputVal.length;
  caretPosition = updatedLength - originalLength + caretPosition;
  if (input[0] instanceof HTMLInputElement) {
    const firstInput: HTMLInputElement = input[0] as HTMLInputElement;

    firstInput.setSelectionRange(caretPosition, caretPosition);
  }
};
